import { FilterMetadata } from 'primeng/api';
import { ExportConstants } from 'src/app/common/global-constants';
import { ExportContentType } from 'src/app/common/global-constants/export-content-type';
import { GridTemplate } from '../../../../core/enums/grid-template';
import { AppliedSettings } from '../models/bcast-models/perform-assessment';

export class Pagination {
  index: number;
  size: number;
  offset: number;
}

export class PaginationResponse {
  count: number;
  current: number;
  pages: number;
  index: number;
  size: number;
}

export class Search {
  text: string;
  mode: string;
}

export class SearchRequest {
  constructor(offset: number, size: number, includeCount: boolean, globalFilter?: FilterMetadata) {
    this.pagination = new Pagination();
    this.pagination.index = size === 0 ? 0 : Math.round(offset / size);
    this.pagination.offset = offset;
    this.pagination.size = size;
    this.search = new Search();
    if (globalFilter) {
      this.search.text = globalFilter.value;
      this.search.mode = globalFilter.matchMode;
    }
    this.fields = {};
    this.template = GridTemplate.None;
    this.includeCount = includeCount;
  }
  pagination: Pagination;
  search: Search;
  fields: { [key: string]: { [key: string]: any } };
  template: string;
  includeCount: boolean;
}

export class AccountSearchRequest {
  constructor(offset: number, size: number, filterText?: string, excludeList: string[] = []) {
    this.pagination = new Pagination();
    this.pagination.offset = offset;
    this.pagination.index = size === 0 ? 0 : Math.round(offset / size);
    this.pagination.size = size;
    this.search = new Search();
    this.search.text = filterText ?? '';
    this.assignedAccountCodes = excludeList;
  }
  assignedAccountCodes: string[];
  pagination: Pagination;
  search: Search;
}


export class AccountSearchResponse {
  data: RootSearchItem[];
  pagination: PaginationResponse;
}

export class SearchDataResponse {
  data: RootSearchItem[];
  pagination: PaginationResponse;
}

export class RootSearchItem {
  key: string;
  stats: number[];
  properties: { [key: string]: any };
  references: { [key: string]: any }[];
}

export class SearchResponse {
  count: number;
  data: any;
}

export class FieldSearchItem {
  value: string;
  values: object;
  text: string;
}

export class SearchFieldResponse {
  constructor() {
    this.data = [];
  }
  data: FieldSearchItem[];
}

export class AttachmentResponse {
  constructor() {
    this.data = [];
  }
  data: AttachmentItem[];
}

export class AttachmentItem {
  eurofinsSampleCode: string;
  attachments: Attachment[];
}

export class Attachment {
  code: string;
  name: string;
}



export class SelectionRequest {
  constructor(
    selectAll: boolean,
    selectionCode: string,
    sampleKeys?: string[],
    excludedsamples?: string[],
    globalSearch?: Search,
    fieldInfo?: any,
    template?: string) {
    this.selection = new SampleSelection(selectAll, selectionCode, sampleKeys, excludedsamples);
    this.search = globalSearch;
    this.fields = fieldInfo;
    this.template = template ?? GridTemplate.None;
  }
  selection: SampleSelection;
  search: Search;
  fields: { [key: string]: { [key: string]: any } };
  template: string;
}
export class QuickRequest{
  constructor(cultureCode: string)
  {
this.CultureCode = cultureCode;
  }
  CultureCode: string;
}
export class ExportRequest extends SelectionRequest {
  constructor(
    selectAll: boolean,
    selectionCode: string,
    exportCode: string,
    resultExportSettings: AppliedSettings[],
    cultureCode: string,
    sampleKeys?: string[],
    excludedsamples?: string[],
    globalSearch?: Search,
    fieldInfo?: any,
    template?: string,
  ) {
    super(selectAll, selectionCode, sampleKeys, excludedsamples, globalSearch, fieldInfo, template);
    this.resultExportRequest = new ResultExportRequest(exportCode, resultExportSettings,cultureCode);
  }
  resultExportRequest: ResultExportRequest;
}

export class ResultExportRequest {
  constructor(exportCode: string, appliedSettings: AppliedSettings[], CultureCode:string) {
    this.exportCode = exportCode;
    this.appliedSettings = appliedSettings;
    this.CultureCode = CultureCode;
  }
  exportCode: string;
  appliedSettings: AppliedSettings[];
  CultureCode: string;
}

export class ExportFormat {
  constructor(contentType: string) {
    this.contentType = contentType === ExportConstants.Csv 
      ? ExportContentType.CsvContentType : ExportContentType.ExcelContentType;
  }
  contentType: string;
}

export class PivotSettings extends ExportFormat {
  constructor(pivotType: PivotType, includeTestInfo: boolean, 
    includeParametersInfo: boolean, viewPreference : ViewPreference,
    contentType: string) {
    super(contentType);
    this.pivotType = pivotType;
    this.includeTestInfo = includeTestInfo;
    this.includeParametersInfo = includeParametersInfo;
    this.viewPreference = viewPreference;
  }
  pivotType: PivotType;
  includeTestInfo: boolean;
  includeParametersInfo: boolean;
  viewPreference : ViewPreference;
}

export enum PivotType {
  TestAndParameter = 'TestAndParameter',
  Test = 'Test',
  Parameter = 'Parameter',
}

export enum ViewPreference {
  SampleAsRows = 'SampleAsRows',
  SampleAsColumns = 'SampleAsColumns',
}

export class FieldSearchRequest {
  constructor(
    field: string,
    filter?: string,
    selectedValues?: string[],
    selectedValuesCollection?: any[]) {
    this.search = new Search();
    this.search.text = filter;
    this.field = field;
    this.mandatoryItems = selectedValues;
    this.mandatoryItemsCollection = selectedValuesCollection;
    this.pagination = new Pagination();
    this.pagination.offset = 0;
    this.pagination.size = 10;
    this.fields = {};
  }
  field: string;
  mandatoryItems?: string[];
  mandatoryItemsCollection?: object;
  pagination: Pagination;
  search: Search;
  fields: { [key: string]: { [key: string]: any } };
}

export class SampleSelection {
  constructor(selectAll: boolean, selectionCode: string, sampleslist?: string[], excludedSamples?: string[]) {
    this.selectAll = selectAll;
    this.keys = sampleslist;
    this.excludeKeys = excludedSamples;
    this.selectionCode = selectionCode ?? 'EurofinsSampleCode';
  }
  selectAll: boolean;
  keys: string[];
  selectionCode: string;
  excludeKeys: string[] = [];
}

export class AttachmentRequest{
  constructor(data : { [key: string]: any}){
    this.data = data;
  }
  data: { [key: string]: any};
}

export class AvailableExport {
  constructor() {
    this.exports = [];
  }
  exports: Export[];
}

export class Export {
  exportCode: string;
  exportName: string;
  hasSettings: boolean;
}

export enum SettingType {
  boolean = 'Boolean',
  text = 'Text',
  list = 'List',
}

export class ExportSettings {
  settings: Settings[] = [];
}

export class Settings {
  settingName: string;
  settingCode: string;
  settingType: SettingType;
  defaultValue?: boolean | string = null;
  items?: Item[];
  get settingValue(): string {
    return this.defaultValue?.toString();
  }
}

export class Item {
  code: string;
  name: string;
}

export class PerformExportModel {
  constructor(selectedSampleKeys: string[], isSelectAll: boolean, baseTable: any, selectedGroupBy: any, searchRequest: SearchRequest,
    unselectedResults?: [], totalRecords?: number) {
    this.selectedGroupBy = selectedGroupBy,
      this.selectedSampleKeys = selectedSampleKeys,
      this.baseTable = baseTable,
      this.searchRequest = searchRequest,
      this.unSelectedResults = unselectedResults,
      this.totalRecords = totalRecords;
    this.isSelectAll = isSelectAll;
  }
  isSelectAll: boolean = false;
  baseTable: any;
  selectedSampleKeys: string[] = [];
  selectedGroupBy: any;
  searchRequest: SearchRequest;
  unSelectedResults?: any = [];
  totalRecords?: number;
}

export class SampleAssessment {
  sampleCount: number;
  assessmemts: Assessments[] = [];
}

export class Assessments {
  complianceAssessmentDataSetName: string;
  complianceAssessmentDataSetCode: string;
}

export class QuickAssessmentResponse {
  fileResponse: any;
  sampleCount: number;
}

export class AssessmentSettingsResponse {
  correctiveParameters: correctiveParameters[] = [];
  datasetCode: string;
  samples: AssessmentSamples[] = [];
  settings: Settings[] = [];
}

export class correctiveParameters {
  correctiveCode: string;
  correctiveName: string;
}

export class AssessmentSamples {
  eurofinsSampleCode: string;
  salesLabCode: string;
  projectCode: string;
  yourSampleCode: string;
  sampleDescription: string;
  corrections: SampleCorrections[] = [];
}

export class SampleCorrections {
  correctiveValue: string;
  resultValue: string;
  correctiveParameter: correctiveParameters;
}

export const ReceptionDateFieldsForFieldCall: Set<string> = new Set(["resultvalue", "resultunit"]);
